// Icon wrapper
.icon {
	min-width: 2rem;
	height: 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	display: inline-flex;
	transition:
		color 300ms,
		background-color 300ms,
		box-shadow 300ms;
	color: var(--#{$prefix}emphasis-color);

	&.ph,
	& > .ph {
		&:not([class*="fs-"]) {
			font-size: 1.15rem;
		}
	}

	&.show {
		background: var(--#{$prefix}body-action-active-bg);
		box-shadow: var(--#{$prefix}input-body-shadow-hover-color);
	}

	&:hover {
		background: var(--#{$prefix}body-action-hover-bg);
		box-shadow: 0 0 0 0.2rem var(--#{$prefix}input-body-shadow-hover-color);
	}
}

.card {
	.icon {
		--#{$prefix}body-action-hover-bg: var(--#{$prefix}hover-grd-bg);
		--#{$prefix}body-action-active-bg: var(--#{$prefix}hover-grd-bg);
	}
}

// Links
.link-bordered {
	border: 1px solid var(--#{$prefix}border-200);
	border-radius: $border-radius;
	padding: 0 0.65rem;
	height: 1.875rem;
	font-size: $font-size-sm;
	color: var(--#{$prefix}body-color);
	display: flex;
	align-items: center;
	transition:
		color 300ms,
		border-color 300ms;
	line-height: 1;

	&:hover {
		color: var(--#{$prefix}emphasis-color);
		border-color: var(--#{$prefix}border-300);
	}

	& > .ph {
		font-size: 1rem;
	}
}

.link-body {
	color: var(--#{prefix}body-color);

	&:hover {
		color: var(--#{$prefix}emphasis-color);
	}
}

// Masonry layout
.masonry {
	column-count: 3;

	@include media-breakpoint-down(xxl) {
		column-count: 2;
	}

	@include media-breakpoint-down(lg) {
		column-count: 1;
	}

	.card {
		display: inline-block;
		width: 100%;
		margin-bottom: 0.5rem;
	}
}

// Custom scrollbar
.scrollbar {
	@include scrollbar(transparent, var(--#{$prefix}scrollbar-bg));
}

// Backdrop
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	transition: opacity 500ms;
	z-index: $nav-bar-z-index + 1;
}

// Action
.bg-highlight {
	background: var(--#{$prefix}hover-grd-bg);
}

.bg-active {
	background: var(--#{$prefix}active-grd-bg);
}

.bg-hover {
	&:hover {
		background: var(--#{$prefix}hover-grd-bg);
	}
}

// Page loader
#page-loader {
	position: fixed;
	inset: 0;
	z-index: 100;
	background-color: var(--#{$prefix}body-bg);
	overflow: hidden;
	display: flex;
	align-items: start;
	font-size: 0.35rem;
	transition: opacity 300ms;
	padding: 0.875rem 0.75rem;

	.placeholder {
		background: var(--#{$prefix}placeholder-load-bg);
	}

	.spinner-border {
		color: var(--#{$prefix}placeholder-load-bg);
	}
}

.page-loader-sidebar {
	flex-shrink: 0;
	width: $sidebar-width;
}
