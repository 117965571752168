#content {
	will-change: padding;
	//transition: padding-left 300ms;
	overflow: hidden;
	display: flex;
	flex-grow: 1;
	z-index: $nav-bar-z-index - 1;
	position: relative;
	height: 100%;
	padding: 0 0.75rem 0.75rem;
	flex-direction: column;

	@include media-breakpoint-up(xl) {
		padding-left: $sidebar-width + 1rem;
	}

	@include media-breakpoint-up(sm) {
		padding-right: $nav-bar-width;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: $nav-bar-mobile-height + 0.75rem;
	}
}

.content-body {
	background-color: var(--#{$prefix}content-body-bg);
	border-radius: 0.8rem;
	border: 0.45rem solid transparent;
	box-shadow: 0 0 0 1px var(--#{$prefix}content-body-border-color);
	margin: 1px;
	position: relative;
	height: 100%;
	width: 100%;
}

.content-body-column {
	display: flex;
	align-items: start;
	overflow: hidden;
	gap: 1rem;
}

.content-body-card {
	isolation: isolate;

	&:before {
		inset: 0;
		position: absolute;
		content: "";
		background-color: var(--#{$prefix}component-bg);
		border-radius: $border-radius;
		z-index: -1;
	}
}

.content-search {
	border: 1px solid var(--#{$prefix}input-body-border-color);
	border-radius: $border-radius-pill;
	background-color: var(--#{$prefix}input-body-bg);
	display: inline-flex;
	align-items: center;
	max-width: 350px;
	width: 100%;
	padding: 0 1rem 0 0.85rem;

	.form-control {
		border: 0;
		background-color: transparent;
		padding-inline: 0.5rem;
		height: 2.25rem;

		&:focus {
			box-shadow: none;
		}

		@include placeholder {
			color: var(--#{$prefix}body-color);
		}
	}

	&:hover {
		box-shadow: 0 0 0 0.2rem var(--#{$prefix}input-body-shadow-hover-color);
	}
}

.content-header {
	height: 2.75rem;
	padding: 0 1rem;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	position: sticky;
	top: 0;
	transform: translateZ(0);
	transition: transform 200ms, opacity 200ms, background-color 200ms;
	z-index: 1;
	isolation: isolate;
	border-radius: $border-radius;

	&:not(.content-header-active) {
		&.scrolling-up {
			transform: translateY(-100%);
			opacity: 0;
		}

		&.scrolling-down {
			opacity: 1;
		}
	}

	&.content-header-active,
	&.scrolling-down {
		background-color: var(--#{$prefix}content-header-stuck-bg);

		.icon-link,
		.icon {
			&.show,
			&:hover {
				background: var(--#{$prefix}content-header-stuck-icon-hover-bg);
			}
		}
	}
}

.sidebar-min {
	#content {
		@include media-breakpoint-up(xl) {
			padding-left: $sidebar-min-bar-width;
		}
	}
}

// Switch blocks in mobile
// e.g Contacts, Email etc.
[data-la-block="list"] {
	flex-shrink: 0;
}

[data-la-block="body"] {
	flex-grow: 1;

	@include media-breakpoint-up(md) {
		padding-left: 0.6rem;
	}
}

@include media-breakpoint-down(md) {
	[data-la-block] {
		will-change: transform;
		transition: transform 300ms;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	[data-la-block="body"] {
		transform: translate3d(105%, 0, 0);
	}

	.content-body-toggled {
		[data-la-block="list"] {
			transform: translate3d(-100%, 0, 0);
		}

		[data-la-block="body"] {
			transform: translate3d(0, 0, 0);
		}
	}
}
