.dropdown-menu {
	backdrop-filter: blur(0.5rem);
}

.dropdown-item,
.dropdown-item-text {
	border-radius: $border-radius;
	display: flex;
	align-items: center;
	font-size: $font-size-sm;

	& > img,
	& > .ph {
		font-size: 1.1rem;
		margin-right: 0.65rem;
	}
}

.dropdown-item {
	border-radius: $border-radius;
	display: flex;
	align-items: center;
	font-size: $font-size-sm;

	& > img,
	& > .ph {
		font-size: 1.1rem;
		margin-right: 0.65rem;
	}
}
