//-------------------------------------
// Custom theme specific variables
//-------------------------------------
// Color mode
$light-highlight: $white;
$dark-highlight: #0091ff;

// Font family
$font-family-icon-light: "Phosphor-Light";

// Layout
$content-padding: 1.25rem;

// Sidebar
$sidebar-width: 14rem;
$sidebar-z-index: 20;
$sidebar-min-bar-width: 3.9rem;
//$sidebar-link-active-color: $link-color;
$sidebar-shadow: 0 0.1rem 0.5rem rgba($black, 0.1);

// Nav bar
$nav-bar-width: 4.5rem;
$nav-bar-z-index: 10;
$nav-bar-mobile-height: 3.25rem;

// Content
$header-height: 4rem;
