[data-simplebar] {
	height: 100%;
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;

	@include hide-scrollbar();

	.simplebar-track {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		&.simplebar-vertical {
			width: 0.4rem;
			right: -2px;

			.simplebar-scrollbar {
				&:before {
					margin: 0.5rem 0;
				}
			}
		}

		&.simplebar-horizontal {
			height: 0.4rem;
		}
	}

	.simplebar-scrollbar {
		&:before {
			background-color: var(--#{$prefix}scrollbar-bg);
		}
	}

	&:hover {
		.simplebar-scrollbar {
			&:before {
				opacity: 1;
			}
		}
	}
}
