.nav-bar {
	position: fixed;
	top: 0;
	right: 0;
	width: $nav-bar-width;
	z-index: $nav-bar-z-index;
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	gap: 0.5rem;

	@include media-breakpoint-down(sm) {
		height: $nav-bar-mobile-height;
		width: 100%;
		bottom: 0;
		top: auto;
		background-color: var(--#{$prefix}body-action-hover-bg);
		box-shadow: 0 0 1rem rgba($gray-900, 0.1);
		flex-direction: row;
		padding: 0 0.25rem;
	}
}

// Color mode
#color-mode-toggle {
	display: grid;
	place-items: center;
	overflow: hidden;
	position: relative;

	.ph {
		position: absolute;
		transform: scale(0);
		opacity: 0;
		transition: transform 300ms, opacity 300ms;
	}
}

[data-bs-theme="light"] {
	#color-mode-toggle {
		.ph-moon {
			transform: scale(100%);
			opacity: 1;
		}
	}
}

[data-bs-theme="dark"] {
	#color-mode-toggle {
		.ph-sun {
			transform: scale(100%);
			opacity: 1;
		}
	}
}

// Top navigation
@keyframes notifyPulse {
	0% {
		transform: scale3d(0, 0, 0);
		border-color: transparent;
	}

	50% {
		border-color: rgba($red, 0.75);
	}

	to {
		transform: scale3d(1, 1, 1);
		border-color: transparent;
	}
}

.nav-bar-menu {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	list-style: none;
	margin: 0.75rem 0 0 0;
	padding: 0;
	gap: 0.75rem;
	flex-direction: column;

	@include media-breakpoint-down(sm) {
		flex-direction: row-reverse;
		margin-top: 0;
		justify-content: space-around;
		width: 100%;
	}

	& > li {
		& > button {
			border: 0;
			background-color: transparent;
			color: var(--#{$prefix}emphasis-color);
			font-size: $font-size-xl;
			min-width: 2.25rem;
			min-height: 2.25rem;
			border-radius: $border-radius;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color 300ms, box-shadow 300ms;

			&:hover {
				background-color: var(--#{$prefix}body-action-hover-bg);
				box-shadow: 0 0 0 0.2rem var(--#{$prefix}input-body-shadow-hover-color);
			}

			&.show {
				background-color: var(--#{$prefix}body-action-active-bg);
				box-shadow: 0 0 0 0.2rem var(--#{$prefix}input-body-shadow-hover-color);

				@include media-breakpoint-down(sm) {
					--icon-active-bg: var(--hover-bg);
				}
			}
		}

		.dropdown-menu {
			&.nav-bar-dropdown-menu {
				left: auto;
				top: 0.75rem;
				right: 3rem;
				position: fixed;
				height: calc(100% - 1.5rem);
				max-width: 21rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				opacity: 0;
				pointer-events: none;
				transform: translate3d(0, 0, 0);
				transition: opacity 300ms, transform 300ms;
				margin-top: 0;

				&.show {
					transform: translate3d(-1rem, 0, 0);
					opacity: 1;
					pointer-events: auto;
				}

				@include media-breakpoint-down(sm) {
					max-width: calc(100% - 1.5rem);
					height: calc(100% - 5.25rem);
					right: 0.75rem;
					top: 2.25rem;

					&.show {
						transform: translate3d(0, -1.5rem, 0);
					}
				}
			}
		}
	}
}

.nav-bar-notify {
	position: relative;
	isolation: isolate;

	&:after {
		will-change: transform, border-color;
		content: "";
		position: absolute;
		width: 2.1875rem;
		height: 2.1875rem;
		border-radius: 50%;
		border: 2px solid transparent;
		z-index: 1;
		inset: 0;
		margin: auto;
		transform: scale3d(0, 0, 0);
		animation-iteration-count: infinite;
		animation-duration: 2000ms;
		backface-visibility: hidden;
		animation-name: notifyPulse;
		pointer-events: none;
	}
}
