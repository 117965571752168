button {
	border: 0;
	padding: 0;
	text-align: left;
	background-color: transparent;
	color: var(--#{$prefix}body-color);

	&:focus,
	&:active {
		outline: none;
		box-shadow: none;
	}
}

.btn-highlight {
	--bs-btn-color: #{$primary-text-emphasis};
	--bs-btn-hover-border-color: #{$primary};
	--bs-btn-hover-color: #{$primary-text-emphasis};
	--bs-btn-hover-bg: #{rgba($primary, 0.075)};
	--bs-btn-font-size: #{$font-size-md};
	--bs-btn-padding-y: 0.4rem;
	--bs-btn-padding-x: 0.85rem;
	--bs-btn-border-radius: #{$border-radius-pill};
	--bs-btn-box-shadow: none;

	border-width: 1.5px;
	border-color: $blue;
	display: flex;
	align-items: center;

	& > .ph {
		font-size: 1.1rem;
		margin: -0.1rem 0.4rem -0.1rem -0.1rem;
	}

	&:hover {
		--bs-btn-box-shadow: 0 0 0 0.2rem #{rgba($primary, 0.1)};
	}
}

.btn-theme {
	--bs-btn-color: var(--bs-btn-theme-color);
	--bs-btn-bg: var(--bs-btn-theme-bg);
	--bs-btn-hover-color: var(--bs-btn-color);
	--bs-btn-hover-bg: var(--bs-btn-theme-hover-bg);
	--bs-btn-active-bg: var(--bs-btn-theme-hover-bg);
	--bs-btn-hover-border-color: var(--bs-btn-theme-bg);
	--bs-btn-active-border-color: var(--bs-btn-theme-bg);
	--bs-btn-active-color: var(--bs-btn-color);
	--bs-btn-box-shadow: none;
	--bs-btn-disabled-bg: var(--bs-btn-theme-bg);
	--bs-btn-disabled-border-color: var(--bs-btn-theme-bg);
	--bs-btn-disabled-color: var(--bs-btn-color);
}

.btn {
	.badge {
		--bs-badge-border-radius: #{$border-radius-sm};
		--bs-badge-padding-x: 0.25rem;
		--bs-badge-padding-y: 0;

		min-width: 1.3rem;
		height: 1.3rem;
		display: inline-grid;
		place-content: center;
		line-height: 1;
		top: 0;
	}
}

.btn-secondary {
	--bs-btn-color: #{lighten($body-emphasis-color, 2.5%)};
	--bs-btn-hover-color: #{lighten($body-emphasis-color, 5%)};
}
