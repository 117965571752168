.icon-link {
	line-height: 1;
	height: 2rem;
	border-radius: $border-radius;
	padding: 0 0.6rem;
	color: var(--#{$prefix}emphasis-color);
	transition: color 300ms, background-color 300ms, box-shadow 300ms;

	&.show {
		background: var(--#{$prefix}body-action-active-bg);
		box-shadow: var(--#{$prefix}input-body-shadow-hover-color);
	}

	&:hover {
		background: var(--#{$prefix}body-action-hover-bg);
		box-shadow: 0 0 0 0.2rem var(--#{$prefix}input-body-shadow-hover-color);
	}

	& > .ph {
		&:not([class*="fs-"]) {
			font-size: 1.1rem;
		}
	}
}
