.input-slider {
	&.noUi-horizontal {
		height: 0.3rem;

		.noUi-handle {
			right: -0.5rem;
			top: -0.35rem;
			height: 1rem;
			width: 1rem;
		}
	}

	.noUi-handle {
		border-radius: 50%;
		border: 0;
		box-shadow: none;
		background-color: var(--#{$prefix}active-invert-bg);
		transition: transform 200ms;

		&:before,
		&:after {
			display: none;
		}

		&.noUi-active {
			transform: scale(1.2);
		}
	}

	.noUi-connect {
		background-color: var(--#{$prefix}active-invert-bg);
		opacity: 0.7;
	}

	&.noUi-target {
		border: 0;
		box-shadow: none;
		background-color: var(--#{$prefix}focus-bg);
	}
}
