.popover-header,
.popover-body {
	overflow: hidden;
	border-radius: $border-radius;
	backdrop-filter: blur(0.5rem);
}

.popover-header {
	--bs-popover-border-width: 0;

	padding-bottom: 0;
}
