.breadcrumb-item {
	& > a {
		color: var(--#{$prefix}body-color);
	}

	& + .breadcrumb-item {
		&:before {
			font-family: $font-family-icon-light;
			font-size: 0.65rem;
			margin-top: 0.15rem;
		}
	}
}
