.chart {
	position: relative;
	height: 16.5rem;

	& > canvas {
		position: relative;
		z-index: 1;
	}

	&.chart-sm {
		height: 10rem;
	}
}

.chart-legends {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 0 1rem;

	& > li {
		display: flex;
		align-items: center;
		font-size: $font-size-sm;

		& > i {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 0.5rem;
		}
	}
}
