.header {
	flex-shrink: 0;
	z-index: $nav-bar-z-index + 1;
	display: flex;
	align-items: center;
	height: $header-height;
	padding: 0 0.75rem;
	border-top: 0;
	width: 100%;

	@include media-breakpoint-down(sm) {
		height: $header-height - 0.75rem;
	}
}
