.modal-content {
	backdrop-filter: blur(0.6rem);
}

.modal-title {
	color: var(--#{$prefix}emphasis-color);
	font-size: $font-size-base;
}

.modal-header {
	.btn-close {
		margin-top: -1.1rem;
		flex-shrink: 0;
	}
}
