textarea,
input {
	color: $input-color;

	&:focus,
	&:active {
		outline: none;
		box-shadow: none;
	}

	@include placeholder {
		color: $input-placeholder-color;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: $font-size-md;
	}
}

textarea {
	resize: none !important;
	@extend .scrollbar;
}

textarea.form-control {
	min-height: 4rem !important;
}

div.form-control {
	input {
		border: 0;
		background-color: transparent;
	}
}

.form-check-input {
	flex-shrink: 0;
	margin-top: 0;
}

.form-check {
	.form-check-input {
		margin-top: 1px;
	}
}

.form-switch {
	.form-check-input {
		@include visually-hidden();

		&:checked {
			& ~ .form-check-label {
				&:before {
					background-color: var(--#{$prefix}active-invert-bg);
					border-color: var(--#{$prefix}active-invert-bg);
				}

				&:after {
					transform: translateX(17px);
					background-color: var(--#{$prefix}switch-thumb-active-bg);
				}
			}
		}

		&:not(:disabled) {
			&:focus {
				& ~ .form-check-label {
					&:before {
						box-shadow: $input-focus-box-shadow;
					}
				}
			}
		}
	}

	.form-check-label {
		position: relative;
		display: flex;
		align-items: center;
		gap: 0.75rem;
		line-height: 1.3;

		&:before,
		&:after {
			content: "";
		}

		&:before {
			width: 35px;
			height: 18px;
			border: 1px solid var(--#{$prefix}border-300);
			display: inline-block;
			vertical-align: middle;
			border-radius: $border-radius-pill;
			transition: border-color 250ms, background-color 250ms;
		}

		&:after {
			position: absolute;
			left: 3px;
			top: 3px;
			height: 12px;
			width: 12px;
			background-color: var(--#{$prefix}active-invert-bg);
			border-radius: 50%;
			transition: transform 250ms, background-color 250ms;
		}
	}
}

.input-group {
	.btn {
		--#{$prefix}btn-border-color: var(--#{$prefix}border-200);
		--#{$prefix}btn-hover-border-color: var(--#{$prefix}border-200);
		--#{$prefix}btn-focus-border-color: var(--#{$prefix}border-300);
		--#{$prefix}btn-active-border-color: var(--#{$prefix}border-300);
		--#{$prefix}btn-hover-bg: var(--#{$prefix}hover-bg);
		--#{$prefix}btn-focus-bg: var(--#{$prefix}hover-bg);
		--#{$prefix}btn-active-bg: var(--#{$prefix}hover-bg);
	}
}

.valid-tooltip,
.invalid-tooltip {
	right: 0;
}
