//---------------------------------
// Options
//---------------------------------
$enable-negative-margins: true;
$enable-cssgrid: true;
$enable-shadows: true;
$prefix: "bs-";

//---------------------------------
// Colors
//---------------------------------
// Gray colors
$gray-100: #ecf3fa;
$gray-200: #e4eff9;
$gray-300: #d9e7f3;
$gray-400: #c0d4e5;
$gray-500: #97b5cf;
$gray-600: #6a95b9;
$gray-700: #48769c;
$gray-800: #275881;
$gray-900: #1c4167;

$grays: (
	"100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
	"900": $gray-900,
);

// Theme colors
$blue: #1e91ff;
$indigo: #5c6bc0;
$purple: #e26cf5;
$pink: #f06292;
$red: #ff6363;
$orange: #fea84c;
$lime: #d4e157;
$green: #10b981;
$teal: #33b6a9;
$cyan: #26c6da;
$black: #000;
$white: #fff;

$theme-colors: (
	"primary": $blue,
	"secondary": $gray-600,
	"success": $green,
	"info": $cyan,
	"warning": $orange,
	"danger": $red,
	"light": $gray-100,
	"dark": $gray-900,
	"teal": $teal,
	"indigo": $indigo,
	"purple": $purple,
	"pink": $pink,
	"lime": $lime,
);

// Emphasis text colors
$info-text-emphasis: darken($cyan, 7.5%);
$success-text-emphasis: darken($green, 5%);
$primary-text-emphasis: darken($blue, 5%);
$warning-text-emphasis: darken($orange, 12%);
$danger-text-emphasis: darken($red, 10%);
$secondary-text-emphasis: $gray-700;

$primary-bg-subtle: rgba($blue, 0.15);
$secondary-bg-subtle: rgba($gray-600, 0.15);
$success-bg-subtle: rgba($green, 0.15);
$info-bg-subtle: rgba($cyan, 0.15);
$warning-bg-subtle: rgba($orange, 0.15);
$danger-bg-subtle: rgba($red, 0.15);
$light-bg-subtle: rgba($white, 0.5);

// Contrast ratio
$min-contrast-ratio: 2;

//---------------------------------
// Component
//---------------------------------
$component-hover-bg: rgba(101, 164, 172, 0.1);
$component-active-bg: rgba(101, 164, 172, 0.2);
$component-active-color: var(--#{$prefix}emphasis-color);

//---------------------------------
// Focus ring
//---------------------------------
$focus-ring-color: var(--#{$prefix}input-shadow-hover-color);
$focus-ring-width: 0.2rem;

$colors: (
	"blue": $blue,
	"indigo": $indigo,
	"purple": $purple,
	"pink": $pink,
	"red": $red,
	"orange": $orange,
	"lime": $lime,
	"green": $green,
	"teal": $teal,
	"cyan": $cyan,
	"black": $black,
	"white": $white,
	"gray": $gray-600,
	"gray-dark": $gray-800,
);

//---------------------------------
// Grid breakpoints
//---------------------------------
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
);

//-----------------------------------------------------
// Spacers
// Added more spacer values ranging from 0.5 to 96
//------------------------------------------------------
$spacer: 1rem;
$spacers: (
	px: 1px,
	0: 0,
	0\.5: $spacer * 0.125,
	1: $spacer * 0.25,
	1\.5: $spacer * 0.375,
	2: $spacer * 0.5,
	2\.5: $spacer * 0.625,
	3: $spacer * 0.75,
	3\.5: $spacer * 0.875,
	4: $spacer,
	5: $spacer * 1.25,
	6: $spacer * 1.5,
	7: $spacer * 1.75,
	8: $spacer * 2,
	9: $spacer * 2.25,
	10: $spacer * 2.5,
	11: $spacer * 2.75,
	12: $spacer * 3,
	14: $spacer * 3.5,
	16: $spacer * 4,
	20: $spacer * 5,
	24: $spacer * 6,
	28: $spacer * 7,
	32: $spacer * 8,
	36: $spacer * 9,
	40: $spacer * 10,
	44: $spacer * 11,
	48: $spacer * 12,
	52: $spacer * 13,
	56: $spacer * 14,
	60: $spacer * 15,
	64: $spacer * 16,
	72: $spacer * 18,
	80: $spacer * 20,
	96: $spacer * 24,
);

//---------------------------------
// Font
//---------------------------------
// Family
$font-family-sans-serif: "Roboto Condensed", sans-serif;

// Size
$font-size-xs: 0.625rem; // 10px
$font-size-sm: 0.75rem; // 12px
$font-size-md: 0.8125rem; // 12px
$font-size-base: 0.90625rem; // 14.5px
$font-size-lg: 1rem; // 16px
$font-size-xl: 1.25rem; // 20px
$font-size-2xl: 1.5rem; // 24px
$font-size-3xl: 1.75rem; // 28px
$font-size-4xl: 2rem; // 32px

$font-sizes: (
	1: $font-size-4xl,
	2: $font-size-3xl,
	3: $font-size-2xl,
	4: $font-size-xl,
	5: $font-size-lg,
	6: $font-size-base,
	7: $font-size-sm,
	8: $font-size-xs,
);

//---------------------------------
// Border
//---------------------------------
// Border color
$border-color: var(--#{$prefix}border-100);
$border-color-translucent: $border-color;

// Border radius
$border-radius: 0.375rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.5rem;

//---------------------------------
// Box shadow
//---------------------------------
$box-shadow: 0 0.5rem 1rem var(--#{$prefix}component-shadow-color);

//---------------------------------
// Body
//---------------------------------
$body-bg: #e6f1f0;
$body-color: $gray-800;
$body-font-size: $font-size-base;
$body-emphasis-color: $gray-900;
$body-secondary-color: $gray-600;

//---------------------------------
// Links
//---------------------------------
$link-color: #0e79df;
$link-decoration: none;
$link-shade-percentage: 10%;

//---------------------------------
// Card
//---------------------------------
$card-bg: var(--#{$prefix}component-bg);
$card-border-width: 0;
$card-cap-bg: transparent;
$card-spacer-x: 1.25rem;
$card-spacer-y: 1.25rem;
$card-title-spacer-y: 1rem;
$card-title-color: var(--#{$prefix}emphasis-color);
$card-border-radius: $border-radius-lg;
$card-inner-border-radius: $border-radius-sm;
$card-cap-padding-x: 1.25rem;
$card-cap-padding-y: 0.75rem;

//---------------------------------
// Breadcrumbs
//---------------------------------
$breadcrumb-font-size: $font-size-md;
$breadcrumb-margin-bottom: 0;
$breadcrumb-item-padding-x: 0.25rem;
$breadcrumb-active-color: var(--bs-body-color);
$breadcrumb-item-padding-x: 0.5rem;
$breadcrumb-divider-color: var(--bs-body-color);
$breadcrumb-divider: "\e13a";

//---------------------------------
// Tooltip
//---------------------------------
$tooltip-bg: var(--#{$prefix}tooltips-bg);
$tooltip-color: var(--#{$prefix}body-emphasis-color);
$tooltip-padding-x: 0.75rem;
$tooltip-padding-y: 0.5rem;
$tooltip-font-size: $font-size-sm;
$tooltip-opacity: 1;

//---------------------------------
// Badge
//---------------------------------
$badge-font-weight: normal;
$badge-padding-y: 0.6em;
$badge-padding-x: 0.75em;
$badge-border-radius: $border-radius-sm;

//---------------------------------
// Dropdown
//---------------------------------
$dropdown-bg: var(--#{$prefix}component-emphasis-bg);
$dropdown-font-size: $font-size-base;
$dropdown-box-shadow: 0 0.25rem 0.5rem var(--#{$prefix}component-shadow-color);
$dropdown-item-padding-x: 0.6rem;
$dropdown-item-padding-y: 0.4rem;
$dropdown-link-hover-bg: var(--#{$prefix}hover-bg);
$dropdown-link-active-bg: var(--#{$prefix}focus-bg);
$dropdown-padding-y: 0.25rem;
$dropdown-padding-x: 0.25rem;
$dropdown-border-color: var(--#{$prefix}component-border-color);
$dropdown-border-radius: $border-radius-lg;
$dropdown-divider-bg: var(--#{$prefix}border-100);

//---------------------------------
// Modal
//---------------------------------
$modal-backdrop-opacity: 0.15;
$modal-header-border-width: 0;
$modal-content-box-shadow-xs: $box-shadow;
$modal-content-box-shadow-sm-up: $box-shadow;
$modal-footer-border-width: 0;
$modal-title-line-height: 1.1;
$modal-header-padding: 1.5rem;
$modal-inner-padding: 1.5rem;
$modal-fade-transform: scale(0.95);
$modal-transition: transform 0.2s;
$modal-content-border-color: var(--#{$prefix}component-border-color);
$modal-content-box-shadow-sm-up: none;
$modal-content-box-shadow-xs: none;
$modal-content-bg: var(--#{$prefix}component-emphasis-bg);

//---------------------------------
// Close
//---------------------------------
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$body-secondary-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
$btn-close-width: 0.5rem;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(300%);

//---------------------------------
// Icon link
//---------------------------------
$icon-link-gap: 0.4rem;

//---------------------------------
// Table
//---------------------------------
$table-bg: transparent;
$table-striped-bg: var(--#{$prefix}hover-light-bg);
$table-hover-bg: var(--#{$prefix}hover-light-bg);
$table-active-bg: var(--#{$prefix}hover-bg);

//---------------------------------
// Blockquote
//---------------------------------
$blockquote-footer-color: $body-secondary-color;

//---------------------------------
// Accordion
//---------------------------------
$accordion-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $body-emphasis-color;
$accordion-icon-width: 0.8rem;
$accordion-button-focus-box-shadow: none;
$accordion-button-focus-border-color: $border-color;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$body-secondary-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$body-emphasis-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

//---------------------------------
// Alert
//---------------------------------
$alert-link-font-weight: normal;
$alert-border-width: 0;

//---------------------------------
// Carousel
//---------------------------------
$carousel-indicator-width: 0.5rem;
$carousel-dark-control-icon-filter: none;

//---------------------------------
// Nav
//---------------------------------
$nav-link-padding-y: 0.45rem;
$nav-link-padding-x: 0.75rem;
$nav-link-color: var(--#{$prefix}emphasis-color);
$nav-link-hover-color: var(--#{$prefix}emphasis-color);

//---------------------------------
// Form
//---------------------------------
// Input
$input-padding-y: 0.6rem;
$input-border-color: var(--#{$prefix}border-200);
$input-disabled-border-color: var(--#{$prefix}border-100);
$input-focus-border-color: var(--#{$prefix}border-300);
$input-box-shadow: 0 0 0 rgba($black, 0);
$input-font-size: $font-size-md;
$input-bg: transparent;
$input-disabled-bg: transparent;

// Checkbox
$form-check-input-width: 1.15rem;
$form-check-input-border: 1.5px solid var(--#{$prefix}input-border-color);
$form-check-input-checked-bg-color: var(--#{$prefix}input-checked-bg);
$form-check-input-focus-border: var(--#{$prefix}input-border-color);
$form-check-input-border-radius: 50%;
$form-check-padding-start: 1.75rem;
$form-check-margin-bottom: 0;
$form-check-input-active-filter: none;
$form-check-min-height: 1.2rem;
$form-check-input-checked-color: $white;

// Switch
$form-switch-padding-start: 0;

// Select
$form-select-box-shadow: none;
$form-select-disabled-color: var(--#{$prefix}secondary-color);
$form-select-disabled-bg: transparent;
$form-select-disabled-border-color: var(--#{$prefix}border-100);

// File
$form-file-button-bg: transparent;
$form-file-button-hover-bg: var(--#{$prefix}hover-bg);

// Validation
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$green}' viewBox='0 0 256 256'><path d='M173.66 98.34a8 8 0 0 1 0 11.32l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 0zM232 128A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104zm-16 0a88 88 0 1 0-88 88 88.1 88.1 0 0 0 88-88z'/></svg>");
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$red}' viewBox='0 0 256 256'><path d='M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88zm-8-80V80a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0zm20 36a12 12 0 1 1-12-12 12 12 0 0 1 12 12z'/></svg>");
$form-feedback-tooltip-padding-y: 0.25rem;
$form-feedback-tooltip-padding-x: 0.5rem;

//---------------------------------
// Progress bar
//---------------------------------
$progress-bg: var(--#{$prefix}border-100);
$progress-box-shadow: none;

//---------------------------------
// HR
//---------------------------------
$hr-border-color: var(--#{$prefix}border-300);

//---------------------------------
// Button
//---------------------------------
$btn-box-shadow: none;
$btn-active-box-shadow: none;
$btn-hover-bg-shade-amount: 5%;
$btn-hover-border-shade-amount: $btn-hover-bg-shade-amount;
$btn-active-bg-shade-amount: 10%;
$btn-active-border-shade-amount: $btn-active-bg-shade-amount;

//---------------------------------
// Images
//---------------------------------
$thumbnail-bg: transparent;

//---------------------------------
// List group
//---------------------------------
$list-group-border-color: var(--#{$prefix}border-100);
$list-group-active-border-color: var(--#{$prefix}border-100);
$list-group-hover-bg: var(--#{$prefix}hover-bg);
$list-group-bg: transparent;

//---------------------------------
// Pagination
//---------------------------------
$pagination-bg: transparent;
$pagination-color: var(--#{$prefix}body-color);
$pagination-hover-bg: var(--#{$prefix}hover-bg);
$pagination-focus-bg: var(--#{$prefix}hover-bg);
$pagination-hover-color: var(--#{$prefix}body-color);
$pagination-focus-color: var(--#{$prefix}body-color);
$pagination-focus-box-shadow: none;
$pagination-disabled-bg: transparent;
$pagination-active-bg: var(--#{$prefix}hover-bg);
$pagination-active-border-color: var(--#{$prefix}border-100);

//---------------------------------
// Popovers
//---------------------------------
$popover-bg: var(--#{$prefix}component-border-color);
$popover-border-color: var(--#{$prefix}component-border-color);
$popover-header-bg: transparent;
$popover-header-padding-y: 0.75rem;

//---------------------------------
// Spinners
//---------------------------------
$spinner-vertical-align: -0.225em;

//---------------------------------
// Toasts
//---------------------------------
$toast-background-color: var(--#{$prefix}component-bg);
$toast-header-background-color: var(--#{$prefix}component-bg);
$toast-header-border-color: transparent;
$toast-border-color: var(--#{$prefix}component-border-color);
$toast-spacing: 1rem;

//---------------------------------
// Input Groups
//---------------------------------
$input-group-addon-bg: transparent;
