// Regular 400
@font-face {
	font-family: "Roboto Condensed";
	src: url("../fonts/roboto-condensed/RobotoCondensed-Regular.woff2") format("woff2"),
		url("../fonts/roboto-condensed/RobotoCondensed-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// Medium 500
@font-face {
	font-family: "Roboto Condensed";
	src: url("../fonts/roboto-condensed/RobotoCondensed-Medium.woff2") format("woff2"),
		url("../fonts/roboto-condensed/RobotoCondensed-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

// Bold 700
@font-face {
	font-family: "Roboto Condensed";
	src: url("../fonts/roboto-condensed/RobotoCondensed-Bold.woff2") format("woff2"), url("../fonts/roboto-condensed/RobotoCondensed-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
