.date-range-picker {
	display: flex;
	align-items: center;
	position: relative;

	& > span {
		position: relative;
		margin: 0 -0.65rem;
	}
}

.date-range-picker-body {
	background-color: var(--#{$prefix}input-body-bg);
	border: 1px solid var(--#{$prefix}input-body-border-color);
	transition: background-color 200ms linear, border-color 200ms linear, box-shadow 200ms;
	border-radius: $border-radius-pill;

	&:hover {
		box-shadow: 0 0 0 0.2rem var(--#{$prefix}input-body-shadow-hover-color);
	}

	.form-control,
	.form-control-sm {
		border: 0;
		background-color: transparent;
		cursor: pointer;

		&:focus {
			box-shadow: none;
		}
	}

	.datepicker-dropdown {
		left: 0 !important;
	}
}

.datepicker {
	&.datepicker-dropdown {
		.datepicker-picker {
			border: 1px solid $dropdown-border-color;
			box-shadow: $dropdown-box-shadow;
			padding: 0.75rem;
			background-color: $dropdown-bg;
			backdrop-filter: blur(0.5rem);
			border-radius: $dropdown-border-radius;
		}
	}

	.datepicker-picker {
		font-size: $font-size-sm;
		color: var(--#{$prefix}body-color);
		background-color: transparent;

		.datepicker-footer {
			background-color: transparent;
			box-shadow: none;

			.datepicker-controls {
				margin-top: 0.5rem;
				margin-right: 0.25rem;
				justify-content: flex-end;

				.btn {
					all: unset;
					padding: 0.25rem 0.5rem;
					border-radius: $border-radius;
					color: var(--#{$prefix}emphasis-color);
				}
			}
		}

		.dow {
			font-weight: $font-weight-normal;
			font-size: $font-size-sm;
		}
	}

	.datepicker-view {
		width: 15rem;
	}

	.datepicker-controls {
		.view-switch {
			order: -1;
			justify-content: flex-start !important;
		}

		.btn {
			box-shadow: none;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			border: none;
			color: var(--#{$prefix}emphasis-color);
			font-weight: normal !important;

			&:not(:disabled) {
				&:focus,
				&:active,
				&:hover {
					background: var(--#{$prefix}hover-grd-bg);
					color: var(--#{$prefix}emphasis-color);
				}
			}
		}
	}

	.datepicker-header {
		margin-bottom: 0.5rem;
	}

	.datepicker-cell {
		height: 2rem;

		&.next,
		&.prev {
			&,
			&:not(.disabled) {
				color: var(--#{$prefix}secondary-color);
			}
		}

		&:not(.disabled):not(.range-start):not(.range-end):not(.selected) {
			&:hover {
				background: var(--#{$prefix}hover-grd-bg);
			}
		}

		&.disabled {
			color: var(--#{$prefix}secondary-color);
		}

		&.range {
			background: var(--#{$prefix}hover-bg);

			&:not(.disabled):not(.focused):not(.today):hover {
				background: var(--#{$prefix}hover-bg);
			}
		}

		&.selected {
			&,
			&:hover {
				background: var(--#{$prefix}active-invert-bg);
				color: $white;
				font-weight: normal;
			}
		}

		&.focused {
			&:not(.selected) {
				background-color: var(--#{$prefix}focus-bg);
			}
		}

		&.range-end:not(.selected),
		&.range-start:not(.selected):not(.range-end) {
			background-color: var(--#{$prefix}active-invert-bg);
		}

		&.today {
			&:not(.selected) {
				color: var(--#{$prefix}body-color) !important;
				background-color: var(--#{$prefix}focus-bg) !important;
			}
		}
	}
}
